<nav class="navbar navbar-expand-lg navbar-dark bg-primary d-print-none encima">
    <div class="container-fluid">
        <a class="navbar-brand clickable">Gestión A</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">

                <li *ngIf="nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarMantenimientos" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Mantenimientos</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuMantenimientos; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersMantenimientos.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="mostrarCompras && nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarCompras" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Compras</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuCompras; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersCompras.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="mostrarCamping && nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarCamping" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Camping</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuCamping; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersCamping.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="mostrarTienda && nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarTienda" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Tienda</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuTienda; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersTienda.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="mostrarBar && nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarBar" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Bar</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuBar; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersBar.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="mostrarTaller && nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarBar" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Taller</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuTaller; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersTaller.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="mostrarPeluqueria && nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarBar" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Peluquería</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuPeluqueria; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersPeluqueria.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="mostrarAlmacen && nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarAlmacen" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Almacén</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuAlmacen; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersAlmacen.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="mostrarLogistica && nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarLogistica" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Logística</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuLogistica; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersLogistica.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="mostrarCaja && nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarCaja" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Caja</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuCaja; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersCaja.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="mostrarCaja2 && nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarCaja" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Caja</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuCaja2; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersCaja2.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="mostrarVentas && nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarVentas" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Ventas</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuVentas; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersVentas.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarInformes" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Informes</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuInformes; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersInformes.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="mostrarAgro && nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarInformes" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Agro</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuAgro; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersAgro.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="mostrarContabilidad && nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarInformes" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Contabilidad</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuContabilidad; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersContabilidad.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="nivel > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Utilidades</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuUtilidades; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta' (contextmenu)="abrirPestana(item.ruta)">{{item.texto}}</a>
                            <hr *ngIf="dividersUtilidades.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li *ngIf="nivel == 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Salir</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li>
                            <a class="dropdown-item clickable" (click)="logout()" >Salir</a>
                        </li>
                    </ul>
                </li>

            </ul>
            <!-- <form class="d-flex">
                <input class="form-control me-2" type="search" placeholder="Búsqueda" aria-label="Búsqueda">
                <button class="btn btn-outline-success" type="submit">Búsqueda</button>
            </form> -->
        </div>
    </div>
</nav>