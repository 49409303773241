import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FacturacionPeriodica } from '../interfaces/facturacionPeriodica.interface';
import { FacturaPeriodica } from '../interfaces/facturaPeriodica.interface';
import { FacturaVenta } from '../interfaces/facturaVenta.interface';
import { LiFacturasVenta } from '../interfaces/liFacturasVenta.interface';
import { Vencimiento } from '../interfaces/vencimiento.interface';
import { MisFuncionesService } from './mis-funciones.service';

const base_url = `${ environment.base_url }/FacturasVenta`;


@Injectable({
  providedIn: 'root'
})
export class FacturasService {

  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });

  constructor(
            private http: HttpClient,
            private misFuncionesService: MisFuncionesService,
            ) { }

  // getAll(pagina:number =1, rows:number = 15, desdeNumero:number = 0, hastaNumero: number = 9999999999, desdeSerie: string = '', hastaSerie:string = environment.marcaFinal, desdeCodigoCliente:string = '', hastaCodigoCliente:string = environment.marcaFinal, desdeNombreFiscal:string = '', hastaNombreFiscal:string = environment.marcaFinal, desdeFecha: Date, hastaFecha: Date, formaPago: number = 0, orden:string = 'numeroFactura', desc:number = 0, sinCobrar: number = 0){
  //   const url = `${ base_url }/getAll?page=${pagina}&rows=${rows}&denum=${desdeNumero}&hanum=${hastaNumero}&deser=${desdeSerie
  //                 }&haser=${hastaSerie}&decli=${desdeCodigoCliente}&hacli=${hastaCodigoCliente}&defis=${desdeNombreFiscal
  //                 }&hafis=${hastaNombreFiscal}&defec=${desdeFecha}&hafec=${hastaFecha}&fpag=${formaPago}&order=${orden}&desc=${desc}&nocob=${sinCobrar}`;
    
  //   return this.http.get(url, {headers: this.headers})
  //                   .pipe(
  //                     map(
  //                       (resp:any)=>{
  //                         const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
  //                         return respuesta;
  //                       }
  //                     )
  //                   )
  //                   ;
  // }

  getAll(pagina:number =1, rows:number = 15, desdeNumero:number = 0, hastaNumero: number = 9999999999, desdeSerie: string = '', hastaSerie:string = environment.marcaFinal, desdeCodigoCliente:string = '', hastaCodigoCliente:string = environment.marcaFinal, desdeNombreFiscal:string = '', hastaNombreFiscal:string = environment.marcaFinal, desdeFecha: Date, hastaFecha: Date, formaPago: number = 0, orden:string = 'numeroFactura', desc:number = 0, sinCobrar: number = 0, desdeFechaVto: Date |string = '1980-01-01', hastaFechaVto: Date | string = '2999-12-31'){
    const url = `${ base_url }/getAll?page=${pagina}&rows=${rows}&denum=${desdeNumero}&hanum=${hastaNumero}&deser=${desdeSerie
                  }&haser=${hastaSerie}&decli=${desdeCodigoCliente}&hacli=${hastaCodigoCliente}&defis=${desdeNombreFiscal
                  }&defecvto=${desdeFechaVto}&hafecvto=${hastaFechaVto
                  }&hafis=${hastaNombreFiscal}&defec=${desdeFecha}&hafec=${hastaFecha}&fpag=${formaPago}&order=${orden}&desc=${desc}&nocob=${sinCobrar}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAllTickets(pagina:number =1, rows:number = 15, desdeFecha: string, hastaFecha: string){
    const url = `${ base_url }/getAllTickets/${pagina}/${rows}/${desdeFecha}/${hastaFecha}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getTotalFacturado(pagina:number =1, rows:number = 15, desdeNumero:number = 0, hastaNumero: number = 9999999999, desdeSerie: string = '', hastaSerie: string = environment.marcaFinal, desdeCodigoCliente:string = '', hastaCodigoCliente:string = environment.marcaFinal, desdeNombreFiscal:string = '', hastaNombreFiscal:string = environment.marcaFinal, desdeFecha: Date, hastaFecha: Date, formaPago: number = 0, orden:string = 'numeroFactura', desc:number = 0, sinCobrar: number = 0, desdeFechaVto: Date |string = '1980-01-01', hastaFechaVto: Date | string = '2999-12-31'){
    const url = `${ base_url }/getTotalFacturado?page=${pagina}&rows=${rows}&denum=${desdeNumero}&hanum=${hastaNumero}&deser=${desdeSerie
                  }&defecvto=${desdeFechaVto}&hafecvto=${hastaFechaVto
                  }&haser=${hastaSerie}&decli=${desdeCodigoCliente}&hacli=${hastaCodigoCliente}&defis=${desdeNombreFiscal
                  }&hafis=${hastaNombreFiscal}&defec=${desdeFecha}&hafec=${hastaFecha}&fpag=${formaPago}&order=${orden}&desc=${desc}&nocob=${sinCobrar}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

 
  getFacturaId(id:number){
    const url = `${ base_url }/getFacturaId/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevaRectificativa(data:FacturaVenta, idFacturaRectificada: number){
    const url = `${ base_url }/postFacturaRectificativa/${idFacturaRectificada}`;

       
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nueva(data:FacturaVenta, esMesa:number){
    const url = `${ base_url }/postFactura/${esMesa}`;

       
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getUltimoId(){
    const url = `${ base_url }/getUltimoId`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getVentasCliente(desdeFecha:Date, hastaFecha:Date, idCliente: number = 0, pagina:number =1, rows:number = 15, orden:string = 'codigoArticulo', desc:number = 0){
    const url = `${ base_url }/getVentasCliente/${desdeFecha}/${hastaFecha}/${idCliente}?page=${pagina}&rows=${rows}&lorde=${orden}&desc=${desc}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getTotalVentasCliente(desdeFecha:Date, hastaFecha:Date, idCliente: number = 0){
    const url = `${ base_url }/getTotalVentasCliente/${desdeFecha}/${hastaFecha}/${idCliente}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle.total};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getVentasArticulo(desdeFecha:Date, hastaFecha:Date, pagina:number =1, rows:number = 15, orden:string = 'codigoArticulo', desc:number = 0){
    const url = `${ base_url }/getVentasArticulo/${desdeFecha}/${hastaFecha}?page=${pagina}&rows=${rows}&order=${orden}&desc=${desc}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getVentasMensual(desdeFecha:Date, hastaFecha:Date, agruparFormaPago: number = 0){
    const url = `${ base_url }/getVentasMensual/${desdeFecha}/${hastaFecha}/${agruparFormaPago}`;

    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getFacturaNumero(numero: number, ano:number, serie: number){
    const url = `${ base_url }/getFacturaNumero/${numero}/${ano}/${serie}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizar(data:FacturaVenta, id:number, esMesa:number){
    const url = `${ base_url }/putFactura/${id}/${esMesa}`;
    // console.log(data);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  proximaFactura(fecha: string, ciclo: number):string {
    let retorno:string = '';
    const tmpFecha = new Date(fecha);
    let dia: number = tmpFecha.getDate();
    if (dia > 28) {dia = 28;}

    switch (ciclo) {
      case 1:
        tmpFecha.setDate(tmpFecha.getDate() + ciclo);
        retorno =  formatDate(tmpFecha, 'yyyy-MM-dd','en-US');
        break;
      case 7:
        tmpFecha.setDate(tmpFecha.getDate() + ciclo);
        retorno =  formatDate(tmpFecha, 'yyyy-MM-dd','en-US');
        break;
      case 15:
        tmpFecha.setDate(tmpFecha.getDate() + ciclo);
        retorno =  formatDate(tmpFecha, 'yyyy-MM-dd','en-US');
        break;
      case 30:
        if (tmpFecha.getMonth() < 11) {
          retorno = tmpFecha.getFullYear()  + "-" + (tmpFecha.getMonth() + 2) +  "-" + this.misFuncionesService.poner00(dia); 
        }else {
          retorno = (tmpFecha.getFullYear() + 1)  + "-01-" + dia; 
        }
        break;
      case 90:
        if (tmpFecha.getMonth() < 9) {
          retorno = tmpFecha.getFullYear()  + "-" + (tmpFecha.getMonth() + 4) +  "-" + this.misFuncionesService.poner00(dia); 
        }else {
          retorno = (tmpFecha.getFullYear() + 1)  + "-"+ (tmpFecha.getMonth() - 8 ) + "-" + this.misFuncionesService.poner00(dia); 
        }
        break;
      case 120:
        if (tmpFecha.getMonth() < 8) {
          retorno = tmpFecha.getFullYear()  + "-" + (tmpFecha.getMonth() + 5) +  "-" + this.misFuncionesService.poner00(dia); 
        }else {
          retorno = (tmpFecha.getFullYear() + 1)  + "-"+ (tmpFecha.getMonth() - 7 ) + "-" + this.misFuncionesService.poner00(dia); 
        }
        break;
      case 180:
        if (tmpFecha.getMonth() < 6) {
          retorno = tmpFecha.getFullYear()  + "-" + (tmpFecha.getMonth() + 7) +  "-" + this.misFuncionesService.poner00(dia); 
        }else {
          retorno = (tmpFecha.getFullYear() + 1)  + "-"+ (tmpFecha.getMonth() - 5 ) + "-" + this.misFuncionesService.poner00(dia); 
        }
        break;
      case 365:
        retorno = (tmpFecha.getFullYear() + 1)  + "-" + (tmpFecha.getMonth() + 1) +  "-" + this.misFuncionesService.poner00(dia); 
        break;
      default:
        break;
        }
    return retorno;
  }

  getUltimaFechaFactura(){
    const url = `${ base_url }/getUltimaFechaFactura`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle.fechaFactura};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  validarFechaFactura(idSerie: number, fechaFactura: Date){
    const url = `${ base_url }/validarFechaFactura/${idSerie}/${fechaFactura}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getFacturaOriginal(numero: number, ano:number, serie: number){
    const url = `${ base_url }/getFacturaOriginal/${numero}/${ano}/${serie}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  calcularProximaFactura(ultimaFactura: string, fechaInicio: string, ciclo: number):string {

    // console.log('UltimaFactura: ',ultimaFactura);
    // console.log('fechaInicio: ',fechaInicio);
    // console.log('Ciclo: ', ciclo);
    

    let proximaFactura: string = this.proximaFactura(fechaInicio, ciclo);
    let proximaFacturaUnix: number = this.misFuncionesService.fechaFormatoUnix(proximaFactura);
    const ultimaFacturaUnix:number = this.misFuncionesService.fechaFormatoUnix( ultimaFactura);
    if (ultimaFactura != proximaFactura){
        while (proximaFacturaUnix < ultimaFacturaUnix) {
              // console.log('entro en el while');
              // console.log('Última Factura: ', ultimaFacturaUnix);
              // console.log('Próxima Factura: ', proximaFacturaUnix);
              // console.log('Última Factura: ', ultimaFactura);
              // console.log('Próxima Factura: ', proximaFactura);
              proximaFactura = this.proximaFactura(proximaFactura, ciclo);
              proximaFacturaUnix = this.misFuncionesService.fechaFormatoUnix(proximaFactura);
        }
    }
    // console.log('Próxima Factura: ', proximaFactura);
    
    return proximaFactura;
    
  }

  nuevaFacturaPeriodica(data:FacturaPeriodica){
    const url = `${ base_url }/postFacturaPeriodica`;
       
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getFacturaPeriodicaId(id:number){
    const url = `${ base_url }/getFacturaPeriodicaId/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarFacturaPeriodica(data:FacturaPeriodica){
    const url = `${ base_url }/putFacturaPeriodica/${data.id}`;
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrarFacturaPeriodica(id:number){
    const url = `${ base_url }/borrarFacturaPeriodica/${id}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }
 
  getAllPeriodicas(pagina:number =1, rows:number = 15, desdeNumero:number = 0, hastaNumero: number = 9999999999, desdeSerie: string = '', hastaSerie:string = environment.marcaFinal, desdeCodigoCliente:string = '', hastaCodigoCliente:string = environment.marcaFinal, desdeNombreFiscal:string = '', hastaNombreFiscal:string = environment.marcaFinal, desdeFecha: string, hastaFecha: string, desdeProximaFacturacion: string, hastaProximaFacturacion: string, desdeUltimaFacturacion: string, hastaUltimaFacturacion: string, orden:string = 'numeroFactura', desc:number = 0){
    const url = `${ base_url }/getAllPeriodicas?page=${pagina}&rows=${rows}&denup=${desdeNumero}&hanup=${hastaNumero}&desep=${desdeSerie
                  }&hasep=${hastaSerie}&declp=${desdeCodigoCliente}&haclp=${hastaCodigoCliente}&defip=${desdeNombreFiscal
                  }&hafip=${hastaNombreFiscal}&defep=${desdeFecha}&hafep=${hastaFecha}&depfa=${desdeProximaFacturacion}&hapfa=${hastaProximaFacturacion
                  }&deufa=${desdeUltimaFacturacion}&haufa=${hastaUltimaFacturacion}&order=${orden}&desc=${desc}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  facturacionPeriodica(data:FacturacionPeriodica[]){
    const url = `${ base_url }/facturacionPeriodica`;
       
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getTotalFacturasFamilia(ano:number){
    const url = `${ base_url }/getTotalFacturasFamilia/${ano}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }
  
  getTotalFacturasAnual(ano:number){
    const url = `${ base_url }/getTotalFacturasAnual/${ano}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getVentasHora(desdeFecha:Date, hastaFecha: Date){
    const url = `${ base_url }/getVentasHora/${desdeFecha}/${hastaFecha}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getModelo347(desdeNumero:number = 0, hastaNumero: number = 9999999999, desdeSerie: string = '', hastaSerie:string = environment.marcaFinal, desdeFecha: Date, hastaFecha: Date, clientes:number, proveedores: number, minimoFacturado: number, orden:string = 'numeroFactura', desc:number = 0){
    const url = `${ base_url }/getModelo347?denum=${desdeNumero}&hanum=${hastaNumero}&deser=${desdeSerie
                  }&haser=${hastaSerie}&clies=${clientes}&provs=${proveedores}&minfac=${minimoFacturado
                  }&defec=${desdeFecha}&hafec=${hastaFecha}&order=${orden}&desc=${desc}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getVencimientoIdFactura(idFactura: number){
    const url = `${ base_url }/getVencimientoIdFactura/${idFactura}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  grabarVencimientos(datos:Vencimiento[], idFactura:number){
    const url = `${ base_url }/postVencimientos/${idFactura}`;
    const data = {vencimientos: datos};
       
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getVencimientosRemesados(numRemesa: number, desdeFecha: Date, hastaFecha: Date){
    const url = `${ base_url }/getVencimientosRemesados/${numRemesa}/${desdeFecha}/${hastaFecha}`;
       
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, totalPaginas: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )                    ;
  
                  }
  getVencimientosNoRemesados(numRemesa: number, desdeFecha: Date, hastaFecha: Date, idFormaPago: number = 0){
    const url = `${ base_url }/getVencimientosNoRemesados/${numRemesa}/${desdeFecha}/${hastaFecha}/${idFormaPago}`;
       
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, totalPaginas: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )                    ;
  }

  grabarRemesa(data:number[], fechaEmision: string, fechaEjecucion: string){
    const url = `${environment.base_url}/Archivos/grabarRemesa/${fechaEmision}/${fechaEjecucion}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, numeroRemesa: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrarRemesa(numeroRemesa:number, anoRemesa: number){
    const url = `${ environment.base_url }/Archivos/borrarRemesa/${numeroRemesa}/${anoRemesa}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  grabarCobroFactura(idFactura:number, idCaja: number, entregado: number){
    const url = `${ base_url }/grabarCobroFactura/${idFactura}/${idCaja}/${entregado}`;

       
    return this.http.get(url,{headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getUltimaFactura(){
    const url = `${ base_url }/getUltimaFactura`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  retrocederFactura(id:number){
    const url = `${ base_url }/retrocederFactura/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarObservacionesFactura(id:number, observaciones: string){
    const url = `${ base_url }/actualizarObservacionesFactura/${id}/${observaciones}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarCabeceraFactura(data:FacturaVenta){
    const url = `${ base_url }/actualizarCabeceraFactura/${data.id}`;
    // console.log(data);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevoLiFacturasVentaHis(data:LiFacturasVenta){
    const url = `${ environment.base_url }/HistorialLineas/postLiFacturasVentaHis`;
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  busquedaTicket(valor:string, pagina:number, rows:number){
    const url = `${ base_url }/getBusquedaTicket/${valor}?page=${pagina}&rows=${rows}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  busqueda(valor:string, pagina:number, rows:number, desdeFecha: string, hastaFecha: string){
    const url = `${ base_url }/getBusquedaFactura/${valor}/${desdeFecha}/${hastaFecha}?page=${pagina}&rows=${rows}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  cambiarFormaPago(idFactura:number, idFormaPago: number, descripcionFormaPago: string){
    const url = `${ base_url }/cambiarFormaPago/${idFactura}/${idFormaPago}/${descripcionFormaPago}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getCaFacturas(){
    const url = `${ base_url }/getCaFacturas`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getFacturasDuplicadas(){
    const url = `${ base_url }/getFacturasDuplicadas`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getFacturasFechaNoCorrelativa(){
    const url = `${ base_url }/getFacturasFechaNoCorrelativa`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getLineasFacturasIdNoCorrelativo(){
    const url = `${ base_url }/getLineasFacturasIdNoCorrelativo`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getFacturasIdNoCorrelativo(){
    const url = `${ base_url }/getFacturasIdNoCorrelativo`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getIntegridadLineasFactura(){
    const url = `${ base_url }/getIntegridadLineasFactura`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getFacturasConHueco(){
    const url = `${ base_url }/getFacturasConHueco`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getImportesIncorrectos(){
    const url = `${ base_url }/getImportesIncorrectos`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getListadoRemesa(numeroRemesa: number, anoRemesa: number){
    const url = `${ base_url }/getListadoRemesa/${numeroRemesa}/${anoRemesa}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getExportacionFacturas(data: number[]){
    const url = `${ base_url }/getExportacionFacturas`;
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getExportacionFacturasGestoria(data: number[]){
    const url = `${ base_url }/getExportacionFacturasGestoria`;
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getVencimientosIdsFacturas(data: number[]){
    const url = `${ base_url }/getVencimientosIdsFacturas`;
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }
  
 
}
