import { AfterViewInit, Component, OnInit, enableProdMode } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MisFuncionesService } from './services/mis-funciones.service';
import { ConfiguracionesService } from './services/configuraciones.service';
import { Respuesta } from './interfaces/respuesta.interface';
import { UsuariosService } from './services/usuarios.service';
import { Usuario } from './interfaces/usuario.interface';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit{
  public url:string = '';
  public usuario: string = localStorage.getItem('tp-Usuario') || '';
  public usuarioActivo: Usuario = {};
  public barraVisible:boolean = false;
  public ocultarBarra:boolean = false;
  // public barraMenu = document.getElementById('navBar')!;
  public barraMenu: HTMLElement | undefined ;
  public menuRapidoVisible: boolean = false;


  constructor(
    private configuracionesService: ConfiguracionesService,
    private misFuncionesService: MisFuncionesService,
    private usuariosService: UsuariosService,
  ) { }
  ngAfterViewInit(): void {
    this.barraMenu = document.getElementById('navBar')!;

  }

  ngOnInit(): void {

    

    // El archivo assets configuracion.txt solo debe de usarse para pruebas, ya que si se modifica y no coincide con environment no funcionará el refresco de pantalla ni el enrutamiento porque el auth no actualiza.
    
    this.leerConfiguracion();
    this.cargarConfiguracionDB();
    this.controlNivelUsuario();
  }
  title = 'atpv';
  
  cargarConfiguracionDB(){
    this.configuracionesService.getConfiguraciones().subscribe(resp => {
      if (resp.status != 200){
        console.log(resp.detalle);
      }else{
        environment.diasDefectoConsultas = Number(resp.detalle.diasDefectoConsultas);
      }
    });
  }

  leerConfiguracion(){
    // const conf = configuracion;
    // environment.base_url = conf.base_url;
    this.misFuncionesService.leerConfiguracion().subscribe(
      resp=>{
        const config:any = resp;
        
        this.url = config.base_url;
        environment.base_url = config.base_url;
        if (config.activarAvisoFacturasPeriodicas === 'true') {environment.activarAvisoFacturasPeriodicas = true;}
        if (config.activarAvisoStockMinimo === 'true') {environment.activarAvisoStockMinimo = true;}
        if (config.activarCajaEnFacturas === 'true') {environment.activarCajaEnFacturas = true;}
        if (config.activarClienteFijo === 'true') {environment.activarClienteFijo = true;}
        if (config.activarCobrosEnFacturas === 'true') {environment.activarCobrosEnFacturas = true;}
        if (config.activarCodigoBarrasEnTactil === 'true') {environment.activarCodigoBarrasEnTactil = true;}
        if (config.activarCodigosArticuloAlt === 'true') {environment.activarCodigosArticuloAlt = true;}
        if (config.activarDescuentosVolumen === 'true') {environment.activarDescuentosVolumen = true;}
        if (config.activarDivisas === 'true') {environment.activarDivisas = true;}
        if (config.activarFraseLogin === 'true') {environment.activarFraseLogin = true;}
        if (config.activarGrabacionDirectaLineas === 'true') {environment.activarGrabacionDirectaLineas = true;}
        if (config.activarHistorialLineas === 'true') {environment.activarHistorialLineas = true;}
        if (config.activarMenuRapido === 'true') {this.menuRapidoVisible = true;}
        if (config.activarPrecioConIVAenFormularios === 'true') {environment.activarPrecioConIVAenFormularios = true;}
        if (config.activarTarifas === 'true') {environment.activarTarifas = true;}
        if (config.activarTarifasCompuestas === 'true') {environment.activarTarifasCompuestas = true;}
        if (config.activarTrazabilidad === 'true') {environment.activarTrazabilidad = true;}
        if (config.activarUbicacion === 'true') {environment.activarUbicacion = true;}
        if (config.activarUnaSolaCaja === 'true') {environment.activarUnaSolaCaja = true;}
        if (config.activarVoz === 'true') {environment.activarVoz = true;}
        if (config.actualizarPrecioCoste === 'ultimo') {environment.actualizarPrecioCoste = 'ultimo';}
        if (config.actualizarPrecioTactil === 'true') {environment.actualizarPrecioTactil = true;}
        if (config.actualizarPvpDesdeEntrada === 'true') {environment.actualizarPvpDesdeEntrada = true;}
        if (config.bancoEnFormaPago === 'true') {environment.bancoEnFormaPago = true;}
        if (config.bloqueoCampos === 'true') {environment.bloqueoCampos = true;}
        if (config.codigoArticuloAutomatico === 'true') {environment.codigoArticuloAutomatico = true;}
        if (config.codigoClienteAutomatico === 'true') {environment.codigoClienteAutomatico = true;}
        if (config.crearArticuloDesdeEntrada === 'true') {environment.crearArticuloDesdeEntrada = true;}
        if (config.crearArticuloDesdeOT === 'true') {environment.crearArticuloDesdeOT = true;}
        if (config.crearVehiculoDesdeOT === 'true') {environment.crearVehiculoDesdeOT = true;}
        if (config.descontarTrazasAutomatico === 'true') {environment.descontarTrazasAutomatico = true;}
        if (config.descripcionArticuloLarga === 'true') {environment.descripcionArticuloLarga = true;}
        if (config.familiasArbol === 'true') {environment.familiasArbol = true;}
        if (config.fechaLineaTicket === 'true') {environment.fechaLineaTicket = true;}
        if (config.gmt) {environment.gmt = config.gmt;}
        if (config.imprimirAlGrabar === 'true') {environment.imprimirAlGrabar = config.imprimirAlGrabar;}
        if (config.imprimirTicket === 'true') {environment.imprimirTicket = config.imprimirTicket;}
        if (config.iniciarCombosBlanco === 'true') {environment.iniciarCombosBlanco = config.iniciarCombosBlanco;}
        if (config.loginHastaDomingo === 'true') {environment.loginHastaDomingo = config.loginHastaDomingo;}
        if (config.nuevoAlGrabar === 'false') {environment.nuevoAlGrabar = config.nuevoAlGrabar;}
        if (config.moduloAgro === 'true') {environment.moduloAgro = true;}
        if (config.moduloAlmacen === 'true') {environment.moduloAlmacen = true;}
        if (config.moduloBar === 'true') {environment.moduloBar = true;}
        if (config.moduloCaja === 'true') {environment.moduloCaja = true;}
        if (config.moduloCaja2 === 'true') {environment.moduloCaja2 = true;}
        if (config.moduloCamping === 'true') {environment.moduloCamping = true;}
        if (config.moduloCompras === 'true') {environment.moduloCompras = true;}
        if (config.moduloContabilidad === 'true') {environment.moduloContabilidad = true;}
        if (config.moduloLogistica === 'true') {environment.moduloLogistica = true;}
        if (config.moduloOcr === 'true') {environment.moduloOcr = true;}
        if (config.moduloPeluqueria === 'true') {environment.moduloPeluqueria = true;}
        if (config.moduloTaller === 'true') {environment.moduloTaller = true;}
        if (config.moduloTienda === 'true') {environment.moduloTienda = true;}
        if (config.moduloVentas === 'true') {environment.moduloVentas = true;}
        if (config.ocultarComboArticulos === 'true') {environment.ocultarComboArticulos = true;}
        if (config.ordenComboClientes){environment.ordenComboClientes = config.ordenComboClientes;}
        if (config.permitirModificarCodigoArticulo === 'true') {environment.permitirModificarCodigoArticulo = true;}
        if (config.permitirModificarFacturas === 'true') {environment.permitirModificarFacturas = true;}
        if (config.precioConIVA === 'true') {environment.precioConIVA = true;}
        if (config.precioUnidadTicket === 'true') {environment.precioUnidadTicket = true;}
        if (config.precioxTemporada === 'true') {environment.precioxTemporada = true;}
        if (config.reportEnSerie === 'true') {environment.reportEnSerie = true;}
        if (config.siempreAbrirCajonAlGrabar === 'true') {environment.siempreAbrirCajonAlGrabar = true;}
        if (config.ticketSiCliente9999 === 'true') {environment.ticketSiCliente9999 = true;}
      }
    );
  }

  async controlNivelUsuario(){
    /* -------------------------------------------------------------------------- */
    /*                              CÓDIGOS DE NIVEL                              */
    /* -------------------------------------------------------------------------- */

    // 1 - Usuario de tpv táctil.
    
    
    const token: string = localStorage.getItem('tp-Token') || '';
    if (token.length > 0){
      const resp:Respuesta = await this.usuariosService.getUsuarioToken(token).toPromise();
      if (resp.status != 200){
          console.log(resp.detalle);
      }else{
          this.usuarioActivo = resp.detalle;
          environment.nivel = this.usuarioActivo.nivel!;
          
      }
    }

 }

  navBarVisible(){
    if (this.barraMenu){
        if (this.barraVisible){
          this.barraMenu.style.setProperty("--posicionFinal", "3px");
          this.barraMenu.style.setProperty("--posicionInicial", "-5px");
          this.barraMenu.style.setProperty("--tiempo", "0.5s");
          if(!this.barraFija) {return 'movimientoVertical';}
          return '';
        }else{
           if(!this.barraFija) {return 'oculto'};
           return ''; 
        }
    }else{
      if(!this.barraFija) {return 'oculto'}; 
      return '';
    }
  }
  ocultarNavBar(){
    this.ocultarBarra = true;
    setTimeout(() => {
      if (this.ocultarBarra){
        this.barraVisible = false;
      }
    }, 1000);
  }

  mostrarNavBar(){
    this.barraVisible = true;
  }

  get barraFija(){
    if (localStorage.getItem('fijarBarra') == 'true'){
      return true;
    }
    return false;
  }
}
