import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlbaranEntrada } from '../interfaces/albaranEntrada.interface';
import { MovimientoAlmacen } from '../interfaces/movimientoAlmacen.interface';
import { Recepciones } from '../interfaces/recepciones.interface';
import { MisFuncionesService } from './mis-funciones.service';

const base_url = `${ environment.base_url }/Movimientos`;


@Injectable({
  providedIn: 'root'
})
export class MovimientosService {

  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });

  constructor(private http: HttpClient,
              private misFuncionesService: MisFuncionesService,
    ) { }

  getMercanciaAlmacen(idAlmacen:number, pagina:number, rows:number, orden:string, desc:number, idArticulo: number = 0, fecha: string = this.misFuncionesService.hoy(), idFamilia: number = 0, idProveedor: number = 0){
    const url = `${ base_url }/getMercanciaAlmacen?page=${pagina}&rows=${rows}&order=${orden}&desc=${desc}&alm=${idAlmacen}&art=${idArticulo}&fec=${fecha}&fam=${idFamilia}&pro=${idProveedor}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getValorAlmacen(idAlmacen:number, fecha: string = this.misFuncionesService.hoy()){
    const url = `${ base_url }/getValorAlmacen/${idAlmacen}/${fecha}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getMercanciaStockBajo(idAlmacen:number, pagina:number, rows:number, orden:string, desc:number, idProveedor: number = 0){
    var url = `${ base_url }/getMercanciaStockBajo?page=${pagina}&rows=${rows}&order=${orden}&desc=${desc}&alm=${idAlmacen}`;
    if (idProveedor != 0) url += `&idpro=${idProveedor}` 
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevo(data:MovimientoAlmacen){
    const url = `${ base_url }/postMovimiento`;
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevoTraspaso(data:MovimientoAlmacen[]){
    const url = `${ base_url }/postTraspaso`;
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevaRecepcion(data:Recepciones){
    const url = `${ base_url }/postRecepcion`;

    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevoAlbaranEntrada(data:AlbaranEntrada){
    const url = `${ base_url }/postAlbaranEntrada`;

    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas,};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarRecepcion(data:Recepciones){
    const url = `${ base_url }/putRecepcion/${data.id!}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarAlbaranEntrada(data:AlbaranEntrada){
    const url = `${ base_url }/putAlbaranEntrada/${data.id!}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAlbaranEntradaNumero(numero: string, fecha:Date, idProveedor: number){
    const url = `${ base_url }/getAlbaranEntradaNumero/${numero}/${fecha}/${idProveedor}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getNumeroAlbaranEntradaBis(numeroAlbaran: string, idProveedor: number, fecha:Date){
    const url = `${ base_url }/getNumeroAlbaranEntradaBis/${numeroAlbaran}/${idProveedor}/${fecha}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrarAlbaranEntrada(id:number){
    const url = `${ base_url }/borrarAlbaranEntrada/${id}`;
    // console.log('Llamo a: ', url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAlbaranEntradaId(id:number){
    const url = `${ base_url }/getAlbaranEntradaId/${id}`;
    // console.log('Llamo a: ', url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAllEntradas(pagina:number =1, rows:number = 15, desdeNumero:string, hastaNumero: string = environment.marcaFinal, desdeCodigoProveedor:string = '', hastaCodigoProveedor:string = environment.marcaFinal, desdeNombreFiscal:string = '', hastaNombreFiscal:string = environment.marcaFinal, desdeFecha: Date, hastaFecha: Date, orden:string = 'albaran', desc:number = 0, lote: string = '', facturado:number = 1){
    const url = `${ base_url }/getAllEntradas?page=${pagina}&rows=${rows}&denumc=${desdeNumero}&hanumc=${hastaNumero
                  }&deproc=${desdeCodigoProveedor}&haproc=${hastaCodigoProveedor}&defisc=${desdeNombreFiscal
                  }&hafisc=${hastaNombreFiscal}&defecc=${desdeFecha}&hafecc=${hastaFecha}&orentc=${orden}&desc=${desc}&lotel=${lote}&fact=${facturado}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getComprasProveedor(desdeFecha:Date, hastaFecha:Date, idProveedor: number = 0, pagina:number =1, rows:number = 15, orden:string = 'codigoArticulo', desc:number = 0){
    const url = `${ base_url }/getComprasProveedor/${desdeFecha}/${hastaFecha}/${idProveedor}?page=${pagina}&rows=${rows}&order=${orden}&desc=${desc}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getTotalComprasProveedor(desdeFecha:Date, hastaFecha:Date, idProveedor: number = 0){
    const url = `${ base_url }/getTotalComprasProveedor/${desdeFecha}/${hastaFecha}/${idProveedor}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle.total};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getComprasMensual(desdeFecha:Date, hastaFecha:Date, porIva1: number, porIva2: number, porIva3: number, porIva4: number){
    const url = `${ base_url }/getComprasMensual/${desdeFecha}/${hastaFecha}/${porIva1}/${porIva2}/${porIva3}/${porIva4}`;

    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getTiposIvaCompras(desdeFecha:Date, hastaFecha:Date){
    const url = `${ base_url }/getTiposIvaCompras/${desdeFecha}/${hastaFecha}`;

    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getHistoricoPreciosCoste(idArticulo: number){
    const url = `${ base_url }/getHistoricoPreciosCoste/${idArticulo}`;

    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  busquedaAlbaran(valor:string, pagina:number, rows:number, desdeFecha: string, hastaFecha: string){
    const url = `${ base_url }/getBusquedaAlbaran/${valor}/${desdeFecha}/${hastaFecha}?page=${pagina}&rows=${rows}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }
}
